export const apiPrefix = 'gplan'
export const primary = 'id'
export const perPage = 100

export const state = () => ({
  list: [],
  details: [],
  loading: false,
  canMore: false,
  lastPage: 0
})

export const mutations = {
  setLoading(state, isLoading) {
    state.loading = isLoading
  },
  readAll(state, data) {
    if (data.params.page !== undefined) {
      const page = data.params.page
      data.params.page = 'd'
      const key = JSON.stringify(data.params)
      data.params.page = page
      if (data.items.length < perPage) {
        state.canMore = false
      } else {
        state.canMore = true
      }
      //console.log(state.list.filter((i) => i.key === key))
      if (state.list.filter((i) => i.key === key)[0] === undefined) {
        //console.log('paginated are not set yet')
        state.list.push({ key, data: data.items })
      } else {
        //console.log('paginated already set')
        if (state.lastPage + 1 === data.params.page) {
          //console.log('this is next page')
          state.lastPage++
          state.list.filter((i) => i.key === key)[0].data.push(...data.items)
        } else {
          //console.log('this is same page')
          state.list.filter((i) => i.key === key)[0].data = data.items
        }
      }
    } else {
      const key = JSON.stringify(data.params)
      if (state.list.filter((i) => i.key === key)[0] === undefined) {
        //console.log('are not set yet')
      } else {
        //console.log('already set')
        state.list = state.list.filter((i) => i.key !== key)
      }
      //console.log(key)
      state.list.push({ key, data: data.items })
    }
  }
}

export const getters = {
  getLoading: (state) => {
    return state.loading
  },
  getCanMore: (state) => {
    return state.canMore
  },
  readAll: (state) => (params) => {
    let key = JSON.stringify(params)
    if (params.page !== undefined) {
      const page = params.page
      params.page = 'd'
      key = JSON.stringify(params)
      params.page = page
    }
    return state.list.filter((i) => i.key === key)
  }
}

export const actions = {
  async readAll({ commit }, params) {
    //console.log(params)
    commit('setLoading', true)
    await this.$axios
      .get('u/' + apiPrefix + '/ra', { params, withCredentials: true })
      .then((response) => {
        //console.log(response.data.data)
        commit('setLoading', false)
        commit('readAll', { params, items: response.data.data })
      })
      .catch((error) => {
        //console.log(error)
        commit('setLoading', false)
        //this.dispatch('message/add', { type: 'error', text: error })
      })
  }
}
