export const apiPrefix = 'video'
export const primary = 'id'
export const perPage = 100

export const state = () => ({
  list: [],
  recommendedVideos: [],
  newestVideos: [],
  details: [],
  fetched: [],
  loading: false,
  canMore: false,
  lastPage: 0,
})

export const mutations = {
  setLoading(state, isLoading) {
    state.loading = isLoading
  },
  setFetched(state, videos) {
    state.fetched = videos.data
  },
  readAll(state, data) {
    if (data.params.page !== undefined) {
      const page = data.params.page
      data.params.page = 'd'
      const key = JSON.stringify(data.params)
      data.params.page = page
      state.canMore = data.items.length >= perPage
      // console.log(state.list.filter((i) => i.key === key))
      if (state.list.filter(i => i.key === key)[0] === undefined) {
        // console.log('paginated are not set yet')
        state.list.push({ key, data: data.items })
      } else {
        // console.log('paginated already set')
        if (state.lastPage + 1 === data.params.page) {
          // console.log('this is next page')
          state.lastPage++
          state.list.filter(i => i.key === key)[0].data.push(...data.items)
        } else {
          // console.log('this is same page')
          state.list.filter(i => i.key === key)[0].data = data.items
        }
      }
    } else {
      const key = JSON.stringify(data.params)
      if (state.list.filter(i => i.key === key)[0] === undefined) {
        // console.log('are not set yet')
      } else {
        // console.log('already set')
        state.list = state.list.filter(i => i.key !== key)
      }
      // console.log(key)
      state.list.push({ key, data: data.items })
    }
  },
  readOne(state, data) {
    // let key = JSON.stringify(data.params)
    // console.log(state.details.filter((i) => i[primary] === data.item[primary]))
    if (
      state.details.filter(i => i[primary] === data.item[primary])[0] ===
      undefined
    ) {
      // console.log('is not set yet')
    } else {
      // console.log('already set')
      state.details = state.details.filter(
        i => i[primary] !== data.item[primary]
      )
    }
    // console.log(data.item.data)
    state.details.push(data.item.data)
  },
  setRecommended(state, data) {
    state.recommendedVideos = data
  },
  setNewest(state, data) {
    state.newestVideos = data
  },
}

export const getters = {
  getLoading: state => {
    return state.loading
  },
  getFetched: state => id => {
    return state.fetched.find(video => video.id === parseInt(id))
  },
  getAllFetched: state => {
    return state.fetched
  },
  getCanMore: state => {
    return state.canMore
  },
  readAll: state => params => {
    let key = JSON.stringify(params)
    if (params.page !== undefined) {
      const page = params.page
      params.page = 'd'
      key = JSON.stringify(params)
      params.page = page
    }
    return state.list.filter(i => i.key === key)
  },
  readOne: state => id => {
    let detail = state.details.filter(i => i[primary] === parseInt(id))[0]
    // console.log(detail)
    if (detail === undefined) {
      if (state.list[0] !== undefined) {
        detail = state.list[0].data.filter(i => i[primary] === parseInt(id))[0]
      }
    }
    // console.log(detail)
    return detail
  },
  getRecommended: state => filters => {
    if (filters && typeof filters.difficulty !== 'undefined' && filters.difficulty) {
      const recommendedVideos = state.recommendedVideos.filter(video => {
        return video.difficulty === filters.difficulty
      })
      return recommendedVideos
    }
    return state.recommendedVideos
  },
  getNewest: state => filters => {
    if (filters && typeof filters.difficulty !== 'undefined' && filters.difficulty) {
      const newestVideos = state.newestVideos.filter(video => {
        return video.difficulty === filters.difficulty
      })
      return newestVideos
    }
    return state.newestVideos
  },
}

export const actions = {
  async readAll({ commit }, params) {
    commit('setLoading', true)
    return await this.$axios
      .get('u/' + apiPrefix + '/ra', { params, withCredentials: true })
      .then(response => {
        commit('setLoading', false)
        commit('readAll', { params, items: response.data.data })
      })
      .catch(error => {
        commit('setLoading', false)
      })
  },
  async search({ commit }, params) {
    commit('setLoading', true)
    return await this.$axios
      .get('u/' + apiPrefix + '/s', { params, withCredentials: true })
      .then(response => {
        commit('setLoading', false)
        commit('readAll', { params, items: response.data.data })
      })
      .catch(error => {
        commit('setLoading', false)
      })
  },
  async readOne({ commit }, parameters) {
    const id = parameters.id
    const params = parameters.params
    commit('setLoading', true)
    return await this.$axios
      .get('u/' + apiPrefix + '/ro/' + id, {
        params,
        withCredentials: true,
      })
      .then(response => {
        commit('readOne', { id, params, item: response.data })
        commit('setLoading', false)
        return response
      })
      .catch(error => {
        commit('setLoading', false)
        throw error
      })
  },
  async readSome({ commit }, ids) {
    const videos = await this.$axios.get('u/' + apiPrefix + '/rs/', {
      params: ids,
      withCredentials: true,
    })
    commit('setFetched', videos.data)
  },
  async readRecommended({ commit }) {
    const { data } = await this.$axios.get('u/' + apiPrefix + '/rr/', {
      withCredentials: true,
    })
    commit('setRecommended', data.data)
  },
  async readNewest({ commit }, params) {
    const { data } = await this.$axios.get('u/' + apiPrefix + '/rn/', {
      withCredentials: true,
    })
    commit('setNewest', data.data)
  },
}
