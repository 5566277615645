<template>
  <header class="o-header" :class="getHeaderClass()">
    <nav class="navbar navbar-expand-sm navbar-dark">
      <div class="container">

        <div class="d-flex flex-column">
          <div>
            <nuxt-link to="/" class="navbar-brand">
              <img
                :src="$global.c_asset('images/svg/fitnesee-logo.svg')"
                :height="logoHeight"
                alt="fitnesee.com"
              />
            </nuxt-link>
          </div>
        </div>

        <template v-if="$auth.loggedIn">
          <div class="collapse navbar-collapse ml-4" id="navbarCollapse">
            <nuxt-link to="/course" class="navbar-brand center-icon ml-3 d-none d-lg-block">
              {{ __('navbar.courses', 'Kurzy') }}
            </nuxt-link>
            <nuxt-link to="/plan" class="navbar-brand center-icon ml-3 d-none d-lg-block">
              {{ __('navbar.trainingPlan', 'Cvičební plán') }}
            </nuxt-link>
            <nuxt-link to="/live" class="navbar-brand center-icon ml-3 d-none d-lg-block">
              {{ __('navbar.liveStreams', 'Živé vysílání') }}
            </nuxt-link>
          </div>
          <nuxt-link
            class="btn btn-link btn-search btn-sm ml-auto mr-3 px-3"
            to="/search"
            role="button"
          >
            <span class="sr-only">Search</span>&nbsp;
          </nuxt-link>
          <div
            class="dropdown dropdown-lang"
            :class="{show: dropdownMenuShown}"
          >
            <button
              class="btn btn-link dropdown-toggle pt-sm-0 pl-sm-0 pr-3"
              type="button"
              id="dropdownMenuButton"
              @click="dropdownMenuShown = !dropdownMenuShown"
            >
              <img
                :src="$global.c_asset('images/png/profile-default.png')"
                alt="JAN"
                class="d-sm-inline profile-img mr-2"
              >
              {{ this.$auth.loggedIn && this.$auth.user.navBarName }}
            </button>
            <div
              class="dropdown-menu dropdown-menu-right text-center pb-3"
              :class="{show: dropdownMenuShown}"
              aria-labelledby="dropdownLang"
            >
              <template v-if="windowWidth < 992">
                <nuxt-link to="/course" class="dropdown-item">
                  {{ __('navbar.courses', 'Kurzy') }}
                </nuxt-link>
                <nuxt-link to="/plan" class="dropdown-item">
                  {{ __('navbar.trainingPlan', 'Cvičební plán') }}
                </nuxt-link>
                <nuxt-link to="/live" class="dropdown-item">
                  {{ __('navbar.liveStreams', 'Živé vysílání') }}
                </nuxt-link>
              </template>

              <nuxt-link to="/account" class="dropdown-item">
                {{ __('navbar.dropdownItemProfile', 'Profil') }}
              </nuxt-link>
              <a href="#" class="dropdown-item" @click.prevent="logout">
                {{ __('navbar.dropdownItemLogOut', 'Odhlásit') }}
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <nuxt-link
            :to="{ path: '/', hash: '#price-list' }"
            role="button"
            class="btn btn-link ml-auto px-1 px-sm-2 px-lg-3"
            @click.native="handleScrollToPriceList"
          >
            <img
              :src="$global.c_asset('images/svg/ico-pricing.svg')"
              :alt="__('navbar.pricing', 'Ceník')"
              class="d-md-none"
            >
            <span class="d-none d-md-block">{{ __('navbar.pricing', 'Ceník') }}</span>
          </nuxt-link>
          <button
            type="button"
            class="btn btn-link px-1 px-sm-2 px-lg-3"
            data-toggle="modal"
            data-target="#modalLogin"
            @click="showLogin()"
          >
            <img :src="$global.c_asset('images/svg/ico-login.svg')" alt="Přihlásit se" class="d-md-none">
            <span class="d-none d-md-block">{{ __('navbar.login', 'Přihlásit se') }}</span>
          </button>
          <button
            type="button"
            class="btn btn-primary mx-2 mx-md-4"
            data-toggle="modal"
            data-target="#modalRegister"
            @click="showRegistration()"
          >
            <img :src="$global.c_asset('images/svg/ico-register.svg')" alt="Registrace" class="d-md-none">
            <span class="d-none d-md-block">{{ __('navbar.signup', 'Registrace') }}</span>
          </button>
          <div
            class="dropdown dropdown-lang"
            :class="{show: dropdownMenuShown}"
          >
            <button
              class="btn btn-link dropdown-toggle px-3"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-expanded="false"
              @click="dropdownMenuShown = !dropdownMenuShown"
            >
              <img
                :src="$global.c_asset(`images/svg/ico-lang-${appLanguage}.svg`)"
                alt="CZ"
                class="d-none d-sm-inline mr-2 mb-1">
              {{ $store.getters['locale/getNavBarLabel'](appLanguage) }}
            </button>
            <div
              class="dropdown-menu dropdown-menu-right pb-3"
              :class="{show: dropdownMenuShown}"
              aria-labelledby="dropdownLang"
            >
              <a
                v-for="locale in $store.getters['locale/getLocales']"
                class="dropdown-item"
                href="#"
                @click.prevent="$nuxt.$emit('changeLang',locale)"
              >
                <img
                  :src="$global.c_asset(`images/svg/ico-lang-${locale}.svg`)"
                  :alt="$store.getters['locale/getNavBarLabel'](locale)"
                  class="d-none d-sm-inline mr-2 mb-1"
                >{{ $store.getters['locale/getNavBarLabel'](locale) }}</a>
            </div>
          </div>
        </template>

      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      windowWidth: window.innerWidth,
      dropdownMenuShown: false,
    }
  },
  computed: {
    isAuthenticated() {
      return this.$auth.loggedIn
    },
    logoHeight() {
      return this.windowWidth >= 414 ? 38 : 20;
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
    window.addEventListener('click', (e) => {
      if (this.dropdownMenuShown && !e.target.closest('#dropdownMenuButton')) {
        this.dropdownMenuShown = false
      }
    })
  },
  methods: {
    logout() {
      this.dropdownMenuShown = false
      this.$auth.logout().then(() => {
        this.$gtm.push({ event: 'logout_success' })
        this.$router.push('/')
      })
    },
    showLogin() {
      this.$emit('showLogin')
    },
    showRegistration() {
      this.$emit('showRegistration')
    },
    redirectToAccount() {
      this.$router.push('/account')
    },
    getHeaderClass() {

      if (/\/live\/.*/.test(this.$route.path)) {
        return 'header-live';
      }

      switch (this.$route.path) {
        case '/':
        case '/plan':
          return 'header-home';
        case '/live':
          return 'header-live';
        default:
          return ''
      }
    },
    showSearch() {
      this.$router.push('/search')
    },
    handleScrollToPriceList() {
      if (this.$route.hash) {
        const anchor = document.querySelector('#price-list')

        // Check if the anchor has been found
        if (anchor) {
          window.scrollTo({
            // Scroll so that the anchor is at the top of the view
            top: anchor.getBoundingClientRect().top + window.pageYOffset
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.header-live {
  background-color: #8B1E33;
}

.center-icon {
  text-decoration: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
}

.small {
  font-size: 0.7rem !important;
}

.center-icon:hover {
  color: #EA9606 !important;
}
</style>
