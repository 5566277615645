<template>
  <main class="o-main">
    <div class="container text-center">
      <h1 v-if="error.statusCode === 404">{{ __('error.notFoundTitle', 'Stránka nenalezena') }}</h1>
      <h1 v-else>{{ __('error.otherErrorsTitle', 'Nastala chyba') }}</h1>
      <nuxt-link to="/">{{ __('error.backButton', 'Zpět na hlavní stránku') }}</nuxt-link>
    </div>
  </main>
</template>

<script>
    import Navbar from '../components/navbar'
    import BaseFooter from '../components/base-footer'

    export default {
        name: "error.vue",
        components: { Navbar, BaseFooter },
        props: ['error']
    }
</script>

<style scoped>

</style>
