export default function({ $axios, redirect, app, store }) {
  $axios.onRequest(config => {
    if (
      store.getters['locale/getLocale'] &&
      store.getters['locale/getLocales'].includes(store.getters['locale/getLocale'])
    ) {
      config.headers.common['X-localization'] = store.getters['locale/getLocale']
    }
  })

  $axios.onError((error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      let message = error.response.statusText

      if (error.response.data && error.response.data.error) {
        message = message + ' - ' + error.response.data.error.message
      }

      const code = parseInt(error.response && error.response.status)

      generateFeedbackToUser(app, code, message, redirect)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    } else {
      // Something happened in setting up the request that triggered an Error
      let message = 'Nah. ' + error

      console.log('Request settings failed: ' + message)
    }
  })
}

function generateFeedbackToUser(app, code, message, redirect) {
  switch (code) {
    case 400:
      //redirect('/404')
      break
  }
}
