export const state = () => ({
  url: '',
  gift_url: ''
})

export const getters = {
  getUrl(state) {
    return state.url;
  },
  getGiftUrl(state) {
    return state.gift_url;
  }
}

export const mutations = {
  setUrl(state, response) {
    state.url = response.data;
  },
  setGiftUrl(state, response) {
    state.gift_url = response.data;
  }
}

export const actions = {
  async processSubscriptionPayment({ commit }, parameters) {
     return await this.$axios
      .post('p/subscription/plan/' + parameters.data.plan_id + '/pay', {
        coupon: parameters.data.coupon,
        contact: parameters.form,
        payment_method_id: parameters.payment_method_id,
        currency: parameters.currency,
      }, {
        withCredentials: true
      })
  },
  async processGiftPayment({ commit }, parameters) {
    return await this.$axios
      .post('u/subscription/gift/' + parameters.data.gift_id + '/pay', {
        giftee_name: parameters.data.giftee_name,
        coupon: parameters.data.coupon,
        email: parameters.data.email,
        contact: parameters.form,
        payment_method_id: parameters.payment_method_id,
        currency: parameters.currency,
      }, {
        withCredentials: true,
      })
  },
  async checkPaymentStatus({ commit }) {
    const { data } = await this.$axios.get('p/subscription/check-payment-status/', {
      withCredentials: true,
    })
    return data
  }
}
