export const apiPrefix = 'user'

export const state = () => ({
  contact: null
})

export const mutations = {
  setContact(state, response) {
    state.contact = response.data;
  },
}

export const getters = {
  getContact(state) {
    return state.contact;
  },
}

export const actions = {
  async change({ commit }, parameters) {
    let response = await this.$axios
      .post('p/' + apiPrefix + '/' + parameters.user_id + '/change-password', parameters, {
        withCredentials: true
      });
    return response.data.data;
  },
  async contact({ commit }, parameters) {
    let response = await this.$axios
      .get('p/' + apiPrefix + '/' + parameters.user_id + '/contact', {
        withCredentials: true
      });
    commit('setContact', response.data)
  },
  async setLocale({ commit }, locale) {
    return  await this.$axios.post(`p/${apiPrefix}/setLocale`, {
      locale
    }, {
      withCredentials: true,
    })
  }
}
