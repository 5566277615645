export const state = () => ({
  locale: 'cs',
  defaultLocale: 'cs',
  locales: ['cs', 'en'],
  languageNames: [
    { value: 'cs', label: 'Čeština', navBarLabel: 'CZ' },
    { value: 'en', label: 'English', navBarLabel: 'EN' },
  ],
})

export const getters = {
  getLocale: state => {
    if (state.locale && state.locales.includes(state.locale)) {
      return state.locale
    }
    return state.defaultLocale
  },
  getLocales: state => {
    return state.locales
  },
  getDefaultLocale: state => {
    return state.defaultLocale
  },
  getLanguageNames: state => {
    return state.languageNames
  },
  getNavBarLabel: state => langCode => {
    const language = state.languageNames.find(
      language => language.value === langCode
    )
    return language ? language.navBarLabel : ''
  },
}

export const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale
  },
}

export const actions = {
  setLocale(context, locale) {
    context.commit('SET_LOCALE', locale)
  }
}
