export const apiPrefix = 'coach'
export const primary = 'slug'
export const perPage = 100

export const state = () => ({
  details: [],
  loading: false,
  canMore: false,
  lastPage: 0,
})

export const mutations = {
  setLoading(state, isLoading) {
    state.loading = isLoading
  },
  readOne(state, data) {
    if (
      state.details.filter(i => i[primary] === data.item[primary])[0] ===
      undefined
    ) {
    } else {
      state.details = state.details.filter(
        i => i[primary] !== data.item[primary]
      )
    }
    state.details.push(data.item.data)
  },
  FLUSH_DETAILS(state) {
    state.details = []
  }
}

export const getters = {
  getLoading: state => {
    return state.loading
  },
  getCanMore: state => {
    return state.canMore
  },
  readOne: state => slug => {
    return state.details.filter(i => i[primary] === slug)[0]
  },
}

export const actions = {
  async readOne({ commit }, parameters) {
    const slug = parameters.slug
    const params = parameters.params
    commit('setLoading', true)
    await this.$axios
      .get('u/' + apiPrefix + '/ro/' + slug, {
        params,
        withCredentials: true,
      })
      .then(response => {
        commit('readOne', { slug, params, item: response.data })
        commit('setLoading', false)
      })
      .catch(error => {
        commit('setLoading', false)
      })
  },
  flush({ commit }) {
    commit('FLUSH_DETAILS')
  }
}
