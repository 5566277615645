<template>
  <div>
    <div id="fb-root"></div>
    <trial-info></trial-info>
    <navbar @showLogin="showLogin" @showRegistration="showRegistration" />
    <nuxt @showLogin="showLogin" @showRegistration="showRegistration" />
    <base-footer @showLogin="showLogin" @showRegistration="showRegistration" />
    <custom-modal id="modalLogin" ref="login_modal">
      <div class="row no-gutters">
        <div class="col-lg-7 pr-lg-4">
          <div class="p-4 p-sm-5">
            <h2 id="modalLoginLabel" class="modal-title mb-4">{{ __('default.loginModalTitle', 'Přihlásit se') }}</h2>
            <a
              type="button"
              class="btn btn-light pl-3 font-black"
              @click="loginWithApple"
            >
              <img
                :src="$global.c_asset('images/svg/ico-apple.svg')"
                alt="Sign in with Apple"
                class="mr-2"
              />
              {{ __('default.loginModalSignInWithApple', 'Sign in with Apple') }}
            </a>
            <!--            <div-->
            <!--              -->
            <!--              id="appleid-signin"-->
            <!--              data-color="black"-->
            <!--              data-border="true"-->
            <!--              data-type="sign in"-->
            <!--            ></div>-->
            <hr />
            <form id="loginForm">
              <div class="form-group">
                <label for="formLoginEmail">{{ __('default.loginModalFormEmail', 'E-mail') }}:</label>
                <input
                  id="formLoginEmail"
                  v-model="loginForm.email"
                  type="email"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group">
                <label for="formLoginPassword">{{ __('default.loginModalFormPassword', 'Heslo') }}:</label>
                <input
                  id="formLoginPassword"
                  v-model="loginForm.password"
                  type="password"
                  class="form-control"
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary px-lg-5"
                :disabled="loading"
                @click.prevent="login()"
              >
                {{ __('default.loginModalButton', 'Přihlásit se') }}
                <span
                  v-if="loading"
                  class="spinner-grow spinner-sm ml-2"
                  role="status"
                >
                  <span class="sr-only">...</span>
                </span>
              </button>
            </form>
            <hr />
            <a href="#" @click.prevent="showRegistration()">{{ __('default.loginModalSignUp', 'Registrovat se') }}</a>
            <span class="separ">|</span>
            <a href="#" @click="showPasswordReset">{{ __('default.loginModalForgottenPassword', 'Zapomenuté heslo') }}</a>
          </div>
        </div>
        <div class="col-lg-5 modal-side d-none d-lg-block">
          <modal-sideinfo />
        </div>
      </div>
    </custom-modal>
    <custom-modal id="modalRegister" ref="registration_modal">
      <div class="row no-gutters">
        <div class="col-lg-7 pr-lg-4">
          <div class="p-4 p-sm-5">
            <h2 id="modalRegisterLabel" class="modal-title mb-4">{{ __('default.signUpModalTitle', 'Registrace') }}</h2>
            <p class="lead mb-4">
              <strong>
                {{ __('default.signUpModalSubTitle1part', 'Více než 170 fitness videí kdykoliv a kdekoliv') }}
                <span class="text-primary">{{ __('default.signUpModalSubTitle2part', 'na 7 dní zdarma!') }}</span>
              </strong>
            </p>
            <!--            <div-->
            <!--              id="appleid-signin-2"-->
            <!--              data-color="black"-->
            <!--              data-border="true"-->
            <!--              data-type="sign in"-->
            <!--            ></div>-->
            <a
              type="button"
              class="btn btn-light pl-3 font-black"
              @click="loginWithApple"
            >
              <img
                :src="$global.c_asset('images/svg/ico-apple.svg')"
                alt="Sign in with Apple"
                class="mr-2"
              />
              {{ __('default.signUpModalSignInWithApple', 'Sign in with Apple') }}
            </a>
            <hr />
            <form id="registrationForm">
              <div class="form-group">
                <label for="formRegisterEmail">{{ __('default.signUpModalFormEmail', 'E-mail') }}:</label>
                <input
                  id="formRegisterEmail"
                  v-model="registrationForm.email"
                  name="email"
                  type="email"
                  class="form-control"
                  required
                />
              </div>
              <div class="form-group mb-3">
                <label for="formRegisterPassword">{{ __('default.signUpModalFormPassword', 'Heslo') }}:</label>
                <input
                  id="formRegisterPassword"
                  v-model="registrationForm.password"
                  type="password"
                  name="password"
                  class="form-control"
                  aria-describedby="passwordHelp"
                  minlength="8"
                  required
                />
                <small
                  id="passwordHelp"
                  class="form-text text-muted text-right"
                >
                  {{ __('default.signUpModalFormPasswordSubtext', 'minimálně 8 znaků') }}
                </small>
              </div>
              <div class="custom-control custom-checkbox mb-4 pb-2">
                <input
                  id="customCheck1"
                  name="customCheck1"
                  type="checkbox"
                  class="custom-control-input"
                  required
                />
                <label class="custom-control-label" for="customCheck1">
                  {{ __('default.signUpModalAgreement1part', 'Souhlasím s') }}
                  <a
                    class="cursor-pointer"
                    target="_blank"
                    :href="$global.c_asset(`files/${__('fileName.termsAndConditions','obchodni_podminky')}.pdf`)"
                  >
                    {{ __('default.signUpModalAgreement2part', 'obchodními podmínkami') }}
                  </a>.
                </label>
              </div>
              <button
                type="submit"
                class="btn btn-primary px-lg-5"
                :disabled="loading"
                @click.prevent="register()"
              >
                {{ __('default.signUpModalButton', 'Registrovat se') }}
                <span
                  v-if="loading"
                  class="spinner-grow spinner-sm ml-2"
                  role="status"
                >
                  <span class="sr-only">...</span>
                </span>
              </button>
            </form>
            <hr />
            <a href="#" @click.prevent="showLogin()">{{ __('default.signUpModalLogIn', 'Přihlásit se') }}</a>
          </div>
        </div>
        <div class="col-lg-5 modal-side d-none d-lg-block">
          <modal-sideinfo />
        </div>
      </div>
    </custom-modal>
    <custom-modal id="modalThankYou" ref="thankyou_modal">
      <div class="row no-gutters">
        <div class="col-lg-7 pr-lg-4">
          <div class="p-4 p-sm-5">
            <h2 id="modalRegisterLabel" class="modal-title mb-4">{{ __('default.thankYouModalTitle', 'Děkujeme') }}</h2>
            <p class="lead mb-4">
              <strong>
                {{ __('default.thankYouModalText1part', 'Nyní aktivujte svůj účet') }}
                <span class="text-primary">{{ __('default.thankYouModalText2part', 'odkazem ve Vašem mailu.') }}</span>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </custom-modal>
    <custom-modal id="modalPasswordReset" ref="passwordreset_modal">
      <div class="row no-gutters">
        <div class="col-lg-7 pr-lg-4">
          <div class="p-4 p-sm-5">
            <h2 id="modalPasswordResetLabel" class="modal-title mb-4">
              {{ __('default.passwordResetModalTitle', 'Zapomenuté heslo') }}
            </h2>
            <form id="passwordResetForm">
              <div class="form-group">
                <label for="formPasswordReset">{{ __('default.passwordResetModalFormEmail', 'E-mail') }}:</label>
                <input
                  id="formPasswordReset"
                  v-model="passwordResetForm.email"
                  type="email"
                  class="form-control"
                  required
                />
              </div>
              <button
                type="submit"
                class="btn btn-primary px-lg-5"
                :disabled="loading"
                @click.prevent="passwordReset"
              >
                {{ __('default.passwordResetModalButton', 'Resetovat') }}
                <span
                  v-if="loading"
                  class="spinner-grow spinner-sm ml-2"
                  role="status"
                >
                  <span class="sr-only">...</span>
                </span>
              </button>
            </form>
            <hr />
            <a href="#" @click.prevent="showRegistration()">{{ __('default.passwordResetModalSignUp', 'Registrovat se') }}</a>
            <span class="separ">|</span>
            <a href="#" @click.prevent="showLogin()">{{ __('default.passwordResetModalLogIn', 'Přihlásit se') }}</a>
          </div>
        </div>
        <div class="col-lg-5 modal-side d-none d-lg-block">
          <modal-sideinfo />
        </div>
      </div>
    </custom-modal>
    <custom-modal id="modalPassResetSent" ref="modalPassResetSent_modal">
      <div class="row no-gutters">
        <div class="col-lg-7 pr-lg-4">
          <div class="p-4 p-sm-5">
            <h2 id="modalRegisterLabel" class="modal-title mb-4">{{ __('default.passwordResetSentModalTitle', 'Výborně!') }}</h2>
            <p class="lead mb-4">
              <strong>
                {{ __('default.passwordResetSentModalText1part', 'Odkaz pro dokončení změny hesla') }}
                <br />
                <span class="text-primary">{{ __('default.passwordResetSentModalText2part', 'byl zaslán na Váš mail.') }}</span>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import Navbar from '../components/navbar'
import BaseFooter from '../components/base-footer'
import CustomModal from '../components/custom-modal'
import ModalSideinfo from '../components/modal-sideinfo'
import TrialInfo from "../components/trial-info";

export default {
  name: 'DefaultLayout',
  components: { TrialInfo, Navbar, BaseFooter, CustomModal, ModalSideinfo },
  data() {
    return {
      loading: false,
      loginForm: {
        email: '',
        password: '',
      },
      siwa: {
        email: '',
        id_token: '',
        code: '',
        state: '',
      },
      registrationForm: {
        email: '',
        password: '',
      },
      passwordResetForm: {
        email: '',
      },
      redirect: false,
      reload: false,
      plans_dataset: {
        select: ['*'],
        order: [
          {
            column: 'id',
            dir: 'desc',
          },
        ],
      },
    }
  },
  created() {
    this.$nuxt.$on('showRegistration', (param = {}) => {
      this.showRegistration()
      if (typeof param.redirect !== 'undefined') {
        this.redirect = false
      }
      if (typeof param.reload !== 'undefined') {
        this.reload = true
      }
    })

    this.$nuxt.$on('changeLang', (locale) => {
      this.changeLocale(locale)
    })

    // siwa
    window.AppleID.auth.init({
      clientId: process.env.SIGN_IN_WITH_APPLE_CLIENT_ID,
      scope: 'email',
      redirectURI:
        process.env.APP_URL + process.env.SIGN_IN_WITH_APPLE_REDIRECT,
      state: process.env.SIGN_IN_WITH_APPLE_CSRF_PROTECTION,
      usePopup: true, // or false defaults to false
    })

    if (this.$auth.loggedIn) {
      if (this.$trans.getLocale() !== this.$auth.user.language) {
        this.setAppLocale(this.$auth.user.language)
      }
    } else {
      if (
        localStorage.getItem('locale') &&
        this.$store.getters['locale/getLocales'].includes(localStorage.getItem('locale')) &&
        this.$trans.getLocale() !== localStorage.getItem('locale')
      ) {
        this.setAppLocale(localStorage.getItem('locale'))
      } else {
        // if (this.$store.getters['locale/getLocales'].includes(navigator.language)) {
        //   this.setAppLocale(navigator.language)
        // }
        this.setAppLocale(this.$store.getters['locale/getDefaultLocale'])
      }
    }

    this.$nextTick(() => {
      this.setAppCurrency()
    })

    this.$store.dispatch('plan/readAll', this.plans_dataset)
  },
  methods: {
    showLogin() {
      this.hideAllModals()
      this.$refs.login_modal.showCustomModal()
    },
    showRegistration() {
      this.hideAllModals()
      this.$refs.registration_modal.showCustomModal()
    },
    showPasswordReset() {
      this.hideAllModals()
      this.$refs.passwordreset_modal.showCustomModal()
    },

    hideAllModals() {
      this.$refs.modalPassResetSent_modal.hideCustomModal()
      this.$refs.thankyou_modal.hideCustomModal()
      this.$refs.login_modal.hideCustomModal()
      this.$refs.registration_modal.hideCustomModal()
      this.$refs.passwordreset_modal.hideCustomModal()
    },

    login(data = null) {
      if (
        !this.$global.checkFormValidation(document.querySelector('#loginForm'))
      ) {
        return
      }
      this.loading = true
      return this.$auth
        .loginWith('laravelSanctum', { data: data || this.loginForm })
        .then(() => {
          this.loading = false
          this.$refs.login_modal.hideCustomModal()
          this.afterLogin()
        })
        .catch(() => {
          this.loading = false
          const formInputs = document.querySelectorAll('#loginForm input')
          formInputs.forEach(input => {
            input.classList.add('is-invalid')
          })
        })
    },

    afterLogin() {
      this.setAppLocale(this.$auth.user.language)
      this.$gtm.push({ event: 'login_success' })
      if (this.reload) {
        this.reload = false
        this.$router.go()
      }
      if (this.redirect) {
        this.$router.push('/account')
      } else {
        this.redirect = false
      }
    },

    register() {
      if (
        !this.$global.checkFormValidation(
          document.querySelector('#registrationForm')
        )
      ) {
        return
      }
      this.loading = true
      this.$axios
        .get('/u/sanctum/csrf-cookie', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          withCredentials: true,
        })
        .then(
          function() {
            let registrationData = { ...this.registrationForm }
            this.$axios
              .post('/u/register', registrationData, {
                withCredentials: true,
              })
              .then(response => {
                this.loading = false
                if (
                  response.status === 200 &&
                  response.data.data.confirmation === 'success'
                ) {
                  this.hideAllModals()
                  this.$refs.thankyou_modal.showCustomModal()
                }
              })
              .catch(error => {
                this.loading = false
                const form = document.querySelector('#registrationForm')
                const errors = error.response.data.errors
                // console.log(errors)
                /*
                  const errorlabels = form.querySelectorAll(
                    `[class="invalid-feedback"]`
                  )
                  console.log(errorlabels)
                  errorlabels.forEach((key) => {
                    key.innerHTML = ''
                  })
                  */
                const inputs = form.querySelectorAll(`input.form-control`)
                inputs.forEach(key => {
                  key.classList.remove('is-invalid')
                  key.classList.add('is-valid')
                })

                Object.keys(errors).forEach(key => {
                  const input = form.querySelector(`[name="${key}"]`)
                  /* const inputError = document.querySelector(
                      `[data-belong="${key}"]`
                    ) */
                  input.classList.remove('is-valid')
                  input.classList.add('is-invalid')
                  // inputError.innerHTML = errors[key][0]
                })
              })
          }.bind(this)
        )
    },
    passwordReset() {
      if (
        !this.$global.checkFormValidation(
          document.querySelector('#passwordResetForm')
        )
      ) {
        return
      }
      this.loading = true
      this.$axios
        .get('/u/sanctum/csrf-cookie', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          withCredentials: true,
        })
        .then(
          function() {
            this.loading = false
            // console.log('run run')
            this.$axios
              .post('/u/password-reset', this.passwordResetForm, {
                withCredentials: true,
              })
              .then(response => {
                if (
                  response.status === 200 &&
                  response.data.data.confirmation === 'success'
                ) {
                  this.hideAllModals()
                  this.$refs.modalPassResetSent_modal.showCustomModal()
                }
              })
              .catch(error => {
                this.loading = false
                const formInputs = document.querySelectorAll(
                  '#passwordResetForm input'
                )
                formInputs.forEach(input => {
                  input.classList.add('is-invalid')
                })
              })
          }.bind(this)
        )
    },
    async loginWithApple() {
      try {
        const data = await AppleID.auth.signIn()
        this.siwa.email = data.user ? data.user.email : null
        this.siwa.id_token = data.authorization.id_token
        this.siwa.code = data.authorization.code
        this.siwa.state = data.authorization.state
      } catch (error) {
        return
      }
      this.loading = true
      this.$axios
        .get('/u/sanctum/csrf-cookie', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
          withCredentials: true,
        })
        .then(
          function() {
            this.$axios
              .post('/u/sign-in-with-apple', this.siwa, {
                withCredentials: true,
              })
              .then(response => {
                this.loading = false
                if (response.status === 200) {
                  this.loading = false
                  this.$auth.setUser(response.data.data)
                  this.$refs.login_modal.hideCustomModal()
                  this.$refs.registration_modal.hideCustomModal()
                  this.afterLogin()
                }
              })
              .catch(error => {
                this.loading = false
              })
          }.bind(this)
        )
    },
    changeLocale(locale) {
      if (locale !== this.$store.getters['locale/getLocale']) {
        this.setAppLocale(locale)
        if (this.$auth.loggedIn) {
          this.$store.dispatch('user/setLocale', locale).then(() => {
            this.$auth.fetchUser()
          })
        }
      }
    },
    setAppLocale(locale) {
      this.$store.dispatch('videocategory/flush')
      this.$store.dispatch('coach/flush')
      this.$store.dispatch('course/flush')
      localStorage.setItem('locale', locale)

      this.$nextTick(() => {
        this.$store.dispatch('locale/setLocale', locale)
        this.$trans.setLocale(locale)

        this.$nextTick(() => {
          this.setAppCurrency()
          this.forceUpdateAllChildren(this)
        })
      })
    },
    setAppCurrency() {
      if (this.$auth.loggedIn && this.$auth.user.currency) {
        this.$store.dispatch('currency/setCurrency', this.$auth.user.currency)
      } else {
        let currency = this.$store.getters['currency/getDefaultCurrency'](this.appLanguage)
        this.$store.dispatch('currency/setCurrency', currency)
      }
    },
  },
}
</script>

<style scoped>
.font-black {
  color: black;
}

.font-black:hover {
  color: black;
}

.spinner-sm {
  width: 1em;
  height: 1em;
}

.cursor-pointer:hover {
  cursor: pointer;
  color: #918aa0;
}
</style>
