export const state = () => ({
  type: '',
  data: {},
  price: {},
  active: false
})

export const getters = {
  isActive: (state) => {
    return state.active;
  },
  getType: (state) => {
    return state.type;
  },
  getData: (state) => {
    return state.data;
  },
  getPrice: (state) => {
    return state.price;
  },
}

export const mutations = {
  setPaymentDetails(state, data) {
    state.active = true;
    state.type = data.type;
    state.data = data.data;
    state.price = data.price;
  },
  invalidateForm(state) {
    state.active = false;
  }
}

export const actions = {
  async setPaymentDetails({ commit }, params) {
    commit('setPaymentDetails', params)
  },
  async invalidate({commit}) {
    commit('invalidateForm')
  }
}
