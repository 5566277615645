<template>
  <footer class="o-footer">
    <div class="footer-links">
      <div class="container text-center">
        <ul class="nav nav-socials justify-content-center">
          <li class="nav-item">
            <a class="nav-link link-facebook" target="_blank" href="https://www.facebook.com/fitneseecom">
              <span class="sr-only">befit.tv Facebook</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-instagram" target="_blank" href="https://www.instagram.com/fitnesee_com/">
              <span class="sr-only">befit.tv Instagram</span>
            </a>
          </li>
          <li class="nav-item" v-if="false">
            <a class="nav-link link-twitter" href="#">
              <span class="sr-only">befit.tv Twitter</span>
            </a>
          </li>
          <li class="nav-item" v-if="false">
            <a class="nav-link link-youtube" href="#">
              <span class="sr-only">befit.tv Youtube</span>
            </a>
          </li>
        </ul>
        <ul class="nav nav-footer justify-content-center">
          <li class="nav-item">
            <a
              class="nav-link cursor-pointer"
              @click.prevent="showRegistration"
            >
              {{ __('baseFooter.registration', 'Registrace') }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link cursor-pointer"
              @click.prevent="showLogin"
            >
              {{ __('baseFooter.logIn', 'Přihlášení') }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link cursor-pointer"
              target="_blank"
              :href="$global.c_asset(`files/${__('fileName.termsAndConditions', 'obchodni_podminky')}.pdf`)"
            >
              {{ __('baseFooter.termsAndConditions', 'Obchodní podmínky') }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link cursor-pointer"
              target="_blank"
              :href="$global.c_asset(`files/${__('fileName.privacyProtection', 'ochrana_soukromi')}.pdf`)"
            >
              {{ __('baseFooter.privacyProtection', 'Ochrana soukromí') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container text-center">
      <p>
        ©{{ new Date().getFullYear() }}. BFagency s. r. o. {{ __('baseFooter.allRightsReserved', 'Všechna práva vyhrazna.') }}
      </p>
      <div class="d-flex vertical-center align-items-center justify-content-center mb-4">
        <div class="mr-2">Developed by</div>
        <div>
          <a target="_blank" href="https://snadnee.com">
            <img
                :src="$global.c_asset('images/svg/snadnee.svg')"
                alt="fitnesee.com"
                height="25"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BaseFooter',
  methods: {
    showLogin() {
      this.$emit('showLogin')
    },
    showRegistration() {
      this.$emit('showRegistration')
    },
  }
}
</script>

<style>
  .cursor-pointer:hover {
    cursor: pointer;
    color: #918AA0;
  }
</style>
