<template>
  <div>
    <div
      :id="id"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalNewCustomModalLabel"
      aria-hidden="true"
      @click="handleOutsideClick"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="`modal-${size}`"
      >
        <div class="modal-content">
          <!-- <div class="modal-header">
            <h2 class="modal-title">
              {{ title }}
            </h2>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="hideCustomModal"
            ></button>
          </div> -->
          <div class="modal-body">
            <button
              v-if="closeButton"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="hideCustomModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
    <div id="customModalBackdrop" class="modal-backdrop d-none fade"></div>
  </div>
</template>
<script>
export default {
  name: 'CustomModal',
  props: {
    id: {
      type: String,
      default: 'customModal'
    },
    size: {
      type: String,
      default: 'lg'
    },
    hideOnClickOutside: {
      type: Boolean,
      default: true
    },
    closeButton: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    showCustomModal(event) {
      const modal = document.querySelector('#' + this.id)
      const modalBg = document.querySelector('#customModalBackdrop')
      modal.classList.add('show', 'd-block')
      modalBg.classList.add('show')
      modalBg.classList.remove('d-none')
      document.body.classList.add('modal-open')
    },
    hideCustomModal(event) {
      const modal = document.querySelector('#' + this.id)
      const modalBg = document.querySelector('#customModalBackdrop')
      if (
        event !== undefined &&
        event.target !== modal &&
        !event.target.classList.contains('close')
      )
        return
      modal.classList.remove('show', 'd-block')
      modalBg.classList.remove('show')
      modalBg.classList.add('d-none')
      document.body.classList.remove('modal-open')
    },
    handleOutsideClick(event) {
      if (this.hideOnClickOutside) {
        this.hideCustomModal(event)
      }
    }
  }
}
</script>
