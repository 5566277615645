<template>
  <div>
    <nuxt></nuxt>
  </div>
</template>

<script>
    import Navbar from '../components/navbar'
    import BaseFooter from '../components/base-footer'

    export default {
        name: "print.vue",
    }
</script>

<style scoped>

</style>
