const state = () => ({
  courses: [],
})

const getters = {
  courses(state) {
    return state.courses
  },
}

const actions = {
  async fetchAll({ commit }) {
    const response = await this.$axios.$get('get-courses', {
      withCredentials: true,
    })

    commit('SET_COURSES', response.data)
  },
  async processCoursePayment({ commit }, parameters) {
    return await this.$axios.post('get-purchase-course', {
      courseId: parameters.courseId,
      contact: parameters.form,
      payment_method_id: parameters.payment_method_id,
      currency: parameters.currency,
    }, {
      withCredentials: true,
    })
  },
  async checkPaymentStatus({ commit }, slug) {
    const { data } = await this.$axios.get('course/check-payment-status/' + slug, {
      withCredentials: true,
    })
    return data
  },
  flush({ commit }) {
    commit('FLUSH_COURSES')
  }
}

const mutations = {
  SET_COURSES(state, data) {
    state.courses = data
  },
  FLUSH_COURSES(state) {
    state.courses = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
