export const apiPrefix = 'video-rating'
export const primary = 'id'

export const state = () => ({
  rating: 0,
})

export const getters = {
  getRating(state) {
    return state.rating
  },
}

export const mutations = {
  SET_RATING(state, rating) {
    state.rating = rating
  },
}

export const actions = {
  setRating({ commit }, params) {
    const rating = params.rating
    commit('SET_RATING', rating)
  },
  async rate({ commit }, params) {
    const userId = params.user_id
    const videoId = params.video_id
    const rating = params.rating

    await this.$axios
      .$post(
        'p/' + apiPrefix + `/${userId}/${videoId}`,
        { rating },
        {
          withCredentials: true,
        }
      )
      .then(res => {
        commit('SET_RATING')
      })
  },
}
