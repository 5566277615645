import Vue from 'vue'
import { state as currencyState } from '../store/currency'

const global = {
  c_asset(path) {
    if (process.env.ASSET_URL === undefined) {
      return '/' + path
    }
    return process.env.ASSET_URL + '/dist/' + path
  },
  getDescendantProp(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj)
  },
  userCan(user, roles) {
    return typeof user === 'object' && roles.includes(user.role)
  },
  getHumanVideoLength(time) {
    //console.log(time)
    if (time === '' || typeof time !== 'string') return ''
    let length = time.split(':')
    if (length[0] != '00') {
      return (length[0][0] == '0' ? length[0][1] : length[0]) + ' ' + trans.get('globals.humanVideoLengthHours')
    } else if (length[1] != '00') {
      return (length[1][0] == '0' ? length[1][1] : length[1]) + ' ' + trans.get('globals.humanVideoLengthMinutes')
    } else {
      return (length[2][0] == '0' ? length[2][1] : length[2]) + ' ' + trans.get('globals.humanVideoLengthSeconds')
    }
  },
  videoDifficultyName(difficulty) {
    switch (difficulty) {
      case 1:
        return trans.get('globals.videoDifficultyNameBeginner')
      case 2:
        return trans.get('globals.videoDifficultyNameAdvanced')
      case 3:
        return trans.get('globals.videoDifficultyNameMaster')
    }
  },
  planGoal(goal) {
    switch (goal) {
      case 1:
        return trans.get('globals.planGoalWeightLoss')
      case 2:
        return trans.get('globals.planGoalGainMuscles')
      case 3:
        return trans.get('globals.planGoalPhysicalCondition')
      case 4:
        return trans.get('globals.planGoalYoga')
    }
  },
  checkFormValidation(form) {
    return form.reportValidity()
  },
  getCountries() {
    let module = require('./_countries.js');
    return module.array;
  },
  getDiscountedPrice(price, discount, currency = null) {
    if (!discount) {
      return price
    }
    let numberOfDecimals = 2
    if (currency) {
      numberOfDecimals =
        currencyState().currencyProperties[currency].numberOfDecimals
    }

    switch (numberOfDecimals) {
      case 0:
        return Math.round(price * (100 - discount) / 100)
      case 1:
        return Math.round(price * (100 - discount) / 100 * 10) / 10
      case 2:
        return Math.round(price * (100 - discount) / 100 * 100) / 100
      case 3:
        return Math.round(price * (100 - discount) / 100 * 1000) / 1000
      default:
        return Math.round(price * (100 - discount) / 100 * 100) / 100
    }
  },
  addTime(startTime, endTime) {
    const times = [0, 0, 0]
    const max = times.length

    const a = (startTime || '').split(':')
    const b = (endTime || '').split(':')

    // normalize time values
    for (let i = 0; i < max; i++) {
      a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i])
      b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i])
    }

    // store time values
    for (let i = 0; i < max; i++) {
      times[i] = a[i] + b[i]
    }

    let hours = times[0]
    let minutes = times[1]
    let seconds = times[2]

    if (seconds >= 60) {
      const m = (seconds / 60) << 0
      minutes += m
      seconds -= 60 * m
    }

    if (minutes >= 60) {
      const h = (minutes / 60) << 0
      hours += h
      minutes -= 60 * h
    }

    return (
      ('0' + hours).slice(-2) +
      ':' +
      ('0' + minutes).slice(-2) +
      ':' +
      ('0' + seconds).slice(-2)
    )
  }
}

Vue.filter('convertDate', (date) => {
  if (!date) return null
  let tmp = new Date(date.replace(/-/g, "/") + ' UTC')
  return (
    ('0' + tmp.getDate()).slice(-2) +
    '. ' +
    ('0' + (tmp.getMonth() + 1)).slice(-2) +
    '. ' +
    tmp.getFullYear()
  )
})

Vue.filter('convertDateTime', (date) => {
  if (!date) return null
  let tmp = new Date(date.replace(/-/g, "/") + ' UTC')
  return (
      ('0' + tmp.getDate()).slice(-2) +
      '. ' +
      ('0' + (tmp.getMonth() + 1)).slice(-2) +
      '. ' +
      tmp.getFullYear() +
      ' ' +
      ('0' + tmp.getHours()).slice(-2) +
      ':' +
      ('0' + tmp.getMinutes()).slice(-2)
  )
})

Vue.filter('getDay', (date, yesterday = false) => {
  if (!date) return null

  let days = [
    trans.get('globals.daysInWeekSunday'),
    trans.get('globals.daysInWeekMonday'),
    trans.get('globals.daysInWeekTuesday'),
    trans.get('globals.daysInWeekWednesday'),
    trans.get('globals.daysInWeekThursday'),
    trans.get('globals.daysInWeekFriday'),
    trans.get('globals.daysInWeekSaturday'),
  ];
  let d = new Date(date.replace(/-/g, "/") + ' UTC')
  let dayName = days[d.getDay()];

  let now = new Date();
  if(dayName === days[now.getDay() - 1] && yesterday) {
    return trans.get('globals.daysYesterday');
  }
  if(dayName === days[now.getDay()]) {
    return trans.get('globals.daysToday');
  }
  if(dayName === days[now.getDay() + 1]) {
    return trans.get('globals.daysTomorrow');
  }
  return dayName;
})

Vue.filter('timespan', (date, duration) => {
  if (!date) return null
  let tmp = new Date(date.replace(/-/g, "/") + ' UTC')
  let to = new Date(tmp.getTime() + 60000*duration)
  return ('0' + tmp.getHours()).slice(-2) + ':' + ('0' + tmp.getMinutes()).slice(-2) + ' - '
    + ('0' + to.getHours()).slice(-2) + ':' + ('0' + to.getMinutes()).slice(-2)
})

Vue.filter('convertDateUTC', (date) => {
  if (!date) return null
  let tmp;
  if (date.includes(' ')) {
    tmp = new Date(date.replace(/-/g, "/"))
  } else {
    tmp = new Date(date)
  }
  return (
    ('0' + tmp.getDate()).slice(-2) +
    '. ' +
    ('0' + (tmp.getMonth() + 1)).slice(-2) +
    '. ' +
    tmp.getFullYear()
  )
})

Vue.filter('convertDateAndDay', (date) => {
  if (!date) return null

  let days = [
    trans.get('globals.daysInWeekSunday'),
    trans.get('globals.daysInWeekMonday'),
    trans.get('globals.daysInWeekTuesday'),
    trans.get('globals.daysInWeekWednesday'),
    trans.get('globals.daysInWeekThursday'),
    trans.get('globals.daysInWeekFriday'),
    trans.get('globals.daysInWeekSaturday'),
  ];

  let tmp = new Date(date.replace(/-/g, "/"))
  let dayName = days[tmp.getDay()];
  return (
      dayName + ', ' +
      ('0' + tmp.getDate()).slice(-2) +
      '. ' +
      ('0' + (tmp.getMonth() + 1)).slice(-2) +
      '. ' +
      tmp.getFullYear()
  )
})

// this is to help Webstorm with autocomplete
Vue.prototype.$global = global

import vSelect from "vue-select";
import Lang from "lang.js";
import messages from "../../../assets/js/ll_messages";

Vue.component("v-select", vSelect);

const trans = new Lang({
  messages,
  locale: 'cs',
  fallback: 'en'
});

export default ({ app }, inject) => {
  Vue.mixin({
    methods: {
      __(key, text, count = null) {
        if (trans.has(key)) {
          if (count) {
            return trans.choice(key, count)
          }
          return trans.get(key)
        }
        if (text) {
          console.log(key)
          return text
        }
        return key
      },
      forceUpdateAllChildren($rootElement) {
        $rootElement.$forceUpdate();
        for (let index in $rootElement.$children) {
          $rootElement.$children[index].$forceUpdate();
          this.forceUpdateAllChildren($rootElement.$children[index]);
        }
      }
    },
    computed: {
      appLanguage() {
        return this.$store.getters['locale/getLocale']
      },
      appCurrency() {
        return this.$store.getters['currency/getCurrency']
      },
      priceWithCurrency() {
        return this.$store.getters['currency/getPriceWithCurrency']
      },
      appLocalizedCurrency() {
        let currency = this.$store.getters['currency/getCurrency']

        if (!currency) return null

        return this.__(`globals.currency-${currency}`, currency)
      },
      lowerPlanPrice() {
        let plansStore = this.$store.getters['plan/readAll']({
          select: ['*'],
          order: [
            {
              column: 'id',
              dir: 'desc',
            },
          ],
        })

        if (plansStore[0] === undefined) {
          return null
        }

        let plans = plansStore[0].data
        if (plans.length > 0) {
          let firstPrice = plans[0].current_prices[this.appCurrency] / plans[0].repetition_in_months
          let secondPrice = plans[1].current_prices[this.appCurrency] / plans[1].repetition_in_months
          return Math.min(firstPrice, secondPrice)
        }
      },
      hasValidSubscription() {
        if (this.$auth.loggedIn) {
          let personalSubscription = this.$auth.user.personalSubscription

          if (personalSubscription) {
            return !!(personalSubscription &&
              !(Date.parse(personalSubscription.ends_at) < Date.parse(Date.now())) &&
              !(Date.parse(personalSubscription.valid_to) < Date.parse(Date.now())))
          }
          return false
        }
      },
    },
  })

  inject('trans', trans)

  inject('global', global)
}
