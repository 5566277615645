<template>
  <div class="pl-6 pr-5">
    <div class="d-flex mb-4">
      <p class="mr-3">
        <img
          :src="$global.c_asset('images/svg/ico-benefits-programs.svg')"
          :alt="__('modalSideInfo.usp1lineTitle', 'Cvičení online')"
        />
      </p>
      <p>
        <strong class="lead">{{ __('modalSideInfo.usp1lineTitle', 'Cvičení online') }}</strong>
        <br />{{ __('modalSideInfo.usp1lineText', 'cvičení kdykoliv z tvého počítače nebo tabletu') }}
      </p>
    </div>
    <div class="d-flex mb-4">
      <p class="mr-3">
        <img
          :src="$global.c_asset('images/svg/ico-benefits-lectors.svg')"
          :alt="__('modalSideInfo.usp2lineTitle', 'Skvělí lektoři')"
        />
      </p>
      <p>
        <strong class="lead">{{ __('modalSideInfo.usp2lineTitle', 'Skvělí lektoři') }}</strong>
        <br />{{ __('modalSideInfo.usp2lineText', 'spaluješ kalorie podle nejkvalitnějších cvičících videí') }}
      </p>
    </div>
    <div class="d-flex mb-4">
      <p class="mr-3">
        <img
          :src="$global.c_asset('images/svg/ico-benefits-records.svg')"
          :alt="__('modalSideInfo.usp3lineTitle', '7 dnů zdarma')"
        />
      </p>
      <p>
        <strong class="lead">{{ __('modalSideInfo.usp3lineTitle', '7 dnů zdarma') }}</strong>
        <br />{{ __('modalSideInfo.usp3lineText', 'první týden nic neplatíš') }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalSideinfo'
}
</script>
