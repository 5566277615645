export const state = () => ({
  currency: 'czk',
  currencies: ['czk', 'eur'],
  currencyProperties: {
    czk: {
      numberOfDecimals: 0,
      signPosition: 'after',
      sign: 'Kč',
      internationalSign: 'CZK',
    },
    eur: {
      numberOfDecimals: 2,
      signPosition: 'before',
      sign: '€',
      internationalSign: '€',
    }
  },
  defaultLangValues: {
    cs: 'czk',
    en: 'eur',
  }
})

export const getters = {
  getCurrency: state => {
    if (state.currency && state.currencies.includes(state.currency)) {
      return state.currency
    }
    return state.defaultCurrency
  },
  getCurrencies: state => {
    return state.currencies
  },
  getDefaultCurrency: state => locale => {
    return state.defaultLangValues[locale]
  },
  getCurrencyProperties: state => currency => {
    return state.currencyProperties[currency]
  },
  getPriceWithCurrency: (state, getters, rootState, rootGetters) => price => {
    if (!price) return null
    const currencyProperties = state.currencyProperties[state.currency]
    if (currencyProperties) {
      const numberOfDecimals = currencyProperties.numberOfDecimals
      const signPosition = currencyProperties.signPosition
      let sign = currencyProperties.sign

      if (rootGetters['locale/getLocale'] !== 'cs') {
        sign = currencyProperties.internationalSign
      }

      const roundedPrice = price.toFixed(numberOfDecimals)
      if (signPosition === 'after') {
        return `${roundedPrice} ${sign}`
      }
      return `${sign}${roundedPrice}`
    }
    return price
  },
}

export const mutations = {
  SET_CURRENCY(state, currency) {
    state.currency = currency
  },
}

export const actions = {
  setCurrency({ commit }, currency) {
    commit('SET_CURRENCY', currency)
  }
}
