<template>
  <div v-if="onTrial" class="alert alert-sm mb-0" role="alert">
    <div class="container">
      {{ __('trialInfo.trialInfoText', 'Vaše Fitnesee je ve zkušebním režimu.Řežim končí za') }}
      {{ restTrialDays }} {{ __('trialInfo.days', 'dny', restTrialDays) }}
      ({{ $auth.user.trial_ends_at | convertDateUTC }}) <span class="text-dark mx-2">|</span>
      <nuxt-link to="/account" class="link-more link-more-sm">
        {{ __('trialInfo.extendSubscriptionButton', 'Prodloužit předplatné') }}
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "trial-info",
  computed: {
    onTrial() {
      if (this.$auth.loggedIn) {
        if (!this.$auth.user.has_paid_access) return false
        if (this.hasValidSubscription) return false
        return Date.parse(this.$auth.user.trial_ends_at) > Date.parse(Date())
      }
      return false
    },
    restTrialDays() {
      if (this.$auth.loggedIn && this.$auth.user.trial_ends_at) {
        // number of days left in trial (difference between now and trial_ends_at / number of milliseconds in a day)
        let diff = Math.floor((Date.parse(this.$auth.user.trial_ends_at) - Date.parse(Date())) / 86400000)
        return diff > 0 ? diff : 1
      }
      return 1
    },
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>

</style>
