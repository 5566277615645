import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aaa5dfec = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _532d8634 = () => interopDefault(import('../pages/course/index.vue' /* webpackChunkName: "pages/course/index" */))
const _08c54576 = () => interopDefault(import('../pages/live/index.vue' /* webpackChunkName: "pages/live/index" */))
const _76e2d61a = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _0ac203ff = () => interopDefault(import('../pages/plan.vue' /* webpackChunkName: "pages/plan" */))
const _f00d80fe = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _7828665a = () => interopDefault(import('../pages/account/password-change.vue' /* webpackChunkName: "pages/account/password-change" */))
const _5ee358ca = () => interopDefault(import('../pages/account/settings.vue' /* webpackChunkName: "pages/account/settings" */))
const _7ceef414 = () => interopDefault(import('../pages/payment/paymentResult.vue' /* webpackChunkName: "pages/payment/paymentResult" */))
const _65557182 = () => interopDefault(import('../pages/voucher/buy.vue' /* webpackChunkName: "pages/voucher/buy" */))
const _176b1eac = () => interopDefault(import('../pages/account/activation/_hash.vue' /* webpackChunkName: "pages/account/activation/_hash" */))
const _0677d97d = () => interopDefault(import('../pages/voucher/download/_hash.vue' /* webpackChunkName: "pages/voucher/download/_hash" */))
const _17b80a8f = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _c1e34522 = () => interopDefault(import('../pages/coach/_slug.vue' /* webpackChunkName: "pages/coach/_slug" */))
const _7471eb22 = () => interopDefault(import('../pages/course/_slug/index.vue' /* webpackChunkName: "pages/course/_slug/index" */))
const _0c328806 = () => interopDefault(import('../pages/live/_slug.vue' /* webpackChunkName: "pages/live/_slug" */))
const _88b8bf2c = () => interopDefault(import('../pages/payment/_type.vue' /* webpackChunkName: "pages/payment/_type" */))
const _492772e6 = () => interopDefault(import('../pages/voucher/_hash.vue' /* webpackChunkName: "pages/voucher/_hash" */))
const _71f4f5e0 = () => interopDefault(import('../pages/course/_slug/paymentResult.vue' /* webpackChunkName: "pages/course/_slug/paymentResult" */))
const _baaa1128 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d27e4d4 = () => interopDefault(import('../pages/_id.vue' /* webpackChunkName: "pages/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nonactive',
  linkExactActiveClass: 'nonactive',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _aaa5dfec,
    name: "account"
  }, {
    path: "/course",
    component: _532d8634,
    name: "course"
  }, {
    path: "/live",
    component: _08c54576,
    name: "live"
  }, {
    path: "/password-reset",
    component: _76e2d61a,
    name: "password-reset"
  }, {
    path: "/plan",
    component: _0ac203ff,
    name: "plan"
  }, {
    path: "/search",
    component: _f00d80fe,
    name: "search"
  }, {
    path: "/account/password-change",
    component: _7828665a,
    name: "account-password-change"
  }, {
    path: "/account/settings",
    component: _5ee358ca,
    name: "account-settings"
  }, {
    path: "/payment/paymentResult",
    component: _7ceef414,
    name: "payment-paymentResult"
  }, {
    path: "/voucher/buy",
    component: _65557182,
    name: "voucher-buy"
  }, {
    path: "/account/activation/:hash?",
    component: _176b1eac,
    name: "account-activation-hash"
  }, {
    path: "/voucher/download/:hash?",
    component: _0677d97d,
    name: "voucher-download-hash"
  }, {
    path: "/category/:slug?",
    component: _17b80a8f,
    name: "category-slug"
  }, {
    path: "/coach/:slug?",
    component: _c1e34522,
    name: "coach-slug"
  }, {
    path: "/course/:slug",
    component: _7471eb22,
    name: "course-slug"
  }, {
    path: "/live/:slug",
    component: _0c328806,
    name: "live-slug"
  }, {
    path: "/payment/:type?",
    component: _88b8bf2c,
    name: "payment-type"
  }, {
    path: "/voucher/:hash?",
    component: _492772e6,
    name: "voucher-hash"
  }, {
    path: "/course/:slug/paymentResult",
    component: _71f4f5e0,
    name: "course-slug-paymentResult"
  }, {
    path: "/",
    component: _baaa1128,
    name: "index"
  }, {
    path: "/:id",
    component: _1d27e4d4,
    name: "id"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
