export const apiPrefix = 'voucher'
export const primary = 'hash'
export const perPage = 100

export const state = () => ({
  list: [],
  details: [],
  loading: false,
  canMore: false,
  lastPage: 0
})

export const mutations = {
  setLoading(state, isLoading) {
    state.loading = isLoading
  },
  readOne(state, data) {
    // let key = JSON.stringify(data.params)
    //console.log(state.details.filter((i) => i[primary] === data.item[primary]))
    if (
      state.details.filter((i) => i[primary] === data.item[primary])[0] ===
      undefined
    ) {
      //console.log('is not set yet')
    } else {
      //console.log('already set')
      state.details = state.details.filter(
        (i) => i[primary] !== data.item[primary]
      )
    }
    //console.log(data.item.data)
    state.details.push(data.item.data)
  }
}

export const getters = {
  getLoading: (state) => {
    return state.loading
  },
  getCanMore: (state) => {
    return state.canMore
  },
  readOne: (state) => (id) => {
    let detail = state.details.filter((i) => i[primary] === (id))[0]
    //console.log(detail)
    if (detail === undefined) {
      if (state.list[0] !== undefined) {
        detail = state.list[0].data.filter(
          (i) => i[primary] === (id)
        )[0]
      }
    }
    //console.log(detail)
    return detail
  }
}

export const actions = {
  async readOne({ commit }, parameters) {
    const hash = parameters.hash
    const params = parameters.params
    commit('setLoading', true)
    return await this.$axios
      .get('u/' + apiPrefix + '/ro/' + hash, {
        params,
        withCredentials: true
      })
      .then((response) => {
        commit('readOne', { hash, params, item: response.data })
        commit('setLoading', false)
        return response
      })
      .catch((error) => {
        commit('setLoading', false)
        throw error
      })
  },
  async checkPaymentStatus({ commit }, hash) {
    const { data } = await this.$axios.get(`u/${apiPrefix}/check-payment-status/${hash}`)
    return data
  }
}
